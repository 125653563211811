import React from 'react';

export const Form = ({ onSubmit, isLoading }) => {
  return (
    <form onSubmit={onSubmit}>
      <h2 className="lead-title"> Get Full Access to UPI Course </h2>
      <div className="form-group">
        {/* <label htmlFor="email">Email address</label> */}
        <input type="email" className="form-control" id="email" placeholder="name@example.com" />
      </div>

      <div className="form-group">
        <button className="form-control btn btn-primary lead-submit" type="submit">
          {isLoading ?  'Loading' : 'Next'}
        </button>
      </div>
    </form>
  );
};
export default Form;
